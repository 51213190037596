import React from 'react';
import Logo from '../assets/logo192.png'
export const LoadingCenter = React.memo(() => {
    return (
        <div style={{ width: '100%', height: '100%', position: 'fixed', display: 'grid', placeItems: 'center' }}>
            <div style={{ height: 200, width: 200 }}>
                <div className="anima-box drop-money">
                    <div className="logo-circle box-shadow super-center">
                        <img alt='logo' src={Logo} style={{ width: 45, height: 45 }} />
                    </div>
                </div>
            </div>
        </div>
    );
});
LoadingCenter.displayName = 'LoadingCenter';
